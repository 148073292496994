import { useState } from 'react';
import { Alert, Button } from '@decub8/ui';

import { NETWORKS } from '@src/config';
import { EU_COUNTRIES } from '@src/constants';
import { useAppSelector } from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useSwitchChain } from '@src/hooks/useSwitchChain';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { IdentityVerifiedStatus, StakingModalTabs } from '@src/ts/constants';
import { getCountryCode } from '@src/utils/getters';

import { useBounty } from '../hooks';

import { Deposit } from './Deposit';
import { Message } from './Message';
import { Withdraw } from './Withdraw';

const renderButton = (
    modal_state: string,
    has_stake: boolean,
    can_continue: boolean,
    setCanContinue: (bool: boolean) => void,
    handleClaimBounty: () => void,
    bounty_loading: boolean,
) => {
    switch (modal_state) {
        case StakingModalTabs.Deposit:
            return (
                <div>
                    {has_stake && !can_continue ? (
                        <Message
                            message="You are already staking in this pool."
                            sub_message="Staking more in this pool will extend the unlock date. Are you
        sure you want to continue?"
                            handleClick={() => setCanContinue(true)}
                        />
                    ) : (
                        <Deposit />
                    )}
                </div>
            );
        case 'Bounty':
            return (
                <Button
                    className="w-full"
                    disabled={bounty_loading}
                    onClick={handleClaimBounty}
                    loading={bounty_loading}
                    id="claim-bounty-btn"
                >
                    <strong>Claim bounty</strong>
                </Button>
            );
        default:
            return <Withdraw />;
    }
};

export const Footer: React.FC = () => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const { modal_state, current_pool, pools } = useAppSelector(
        (state) => state.staking,
    );

    const { is_eu_country, has_eu_restriction } = useGlobalContext();

    const has_verified_identity =
        user?.identity_verified === IdentityVerifiedStatus.VERIFIED;

    const users_country_code = getCountryCode(user?.country);

    const is_user_account_eu = users_country_code
        ? EU_COUNTRIES.has(users_country_code)
        : false;

    // TODO: maybe remove the check to see if the ip is in europe incase a user uses a vpn??
    const show_alert =
        has_eu_restriction &&
        ((!user && is_eu_country) ||
            (user &&
                !has_verified_identity &&
                is_eu_country &&
                is_user_account_eu));

    const { contract_idx, chain_id, id, type, has_stake } = pools.find(
        ({ id }) => id === current_pool,
    );

    const [can_continue, setCanContinue] = useState(has_stake ? false : true);

    const { handleClaimBounty, loading } = useBounty(
        contract_idx,
        chain_id,
        id,
        type,
    );

    const { connect, account, connectedChain } = useWeb3Onboard();

    const { setChainID, settingChain } = useSwitchChain();

    const is_connected_correct_chain =
        chain_id === parseInt(connectedChain?.id, 16);

    return (
        <div className="w-full bg-surface-level-two p-5 sticky bottom-0 border-t border-border border-opacity-[0.03]">
            {show_alert && (
                <div className="">
                    <Alert
                        title="Important information"
                        variant="warning"
                        className="mb-5"
                    >
                        <span>
                            You can stake DCB, but our registration flow is
                            temporarily on hold while we obtain our MiCAR
                            license. This means EU users without a verified
                            account won’t be able to obtain an investment tier
                            at this time.{' '}
                            <a
                                target="_blank"
                                className="text-warning hover:underline cursor-pointer"
                                href={`https://www.decubate.com/blog/decubate-micar-ensuring-compliance-and-security-for-our-users`}
                            >
                                Learn more about our licensing process here.
                            </a>
                        </span>
                    </Alert>
                </div>
            )}

            {account ? (
                <>
                    {is_connected_correct_chain ? (
                        <>
                            {renderButton(
                                modal_state,
                                has_stake,
                                can_continue,
                                setCanContinue,
                                handleClaimBounty,
                                loading,
                            )}
                        </>
                    ) : (
                        <Button
                            className="w-full"
                            onClick={async () => {
                                if (settingChain) return;
                                await setChainID(chain_id);
                            }}
                            disabled={settingChain}
                        >
                            {settingChain
                                ? 'Switching Networks'
                                : `Switch to ${NETWORKS[chain_id].network_name}`}
                        </Button>
                    )}
                </>
            ) : (
                <Button className="w-full" onClick={() => connect()}>
                    Connect wallet
                </Button>
            )}
        </div>
    );
};
